import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'

export const useGetShoppingCart = defineStore('useGetShoppingCart', () => {
    const numberProduct = ref<Array<any>>([])
    const count: any = ref({})
    const uniqueProduct = ref<Array<any>>([])
    const isOpenPopupCart = ref(false)
    let seenIds = new Set()
    const checkProduct = () => {
        uniqueProduct.value = []
        seenIds = new Set()
        if (numberProduct.value.length > 0) {
            const lengthNumber = computed(() => {
                numberProduct.value.forEach((item) => {
                    if (!seenIds.has(item.uniqueId)) {
                        seenIds.add(item.uniqueId)
                        uniqueProduct.value.push(item)
                    }
                })

                return uniqueProduct.value.length
            })
            return lengthNumber.value
        }
        return 0
    }
    const countData = computed(() => {
        return numberProduct.value
    })

    onMounted(() => {
        checkProduct()
    })
    return {
        numberProduct,
        checkProduct,
        countData,
        uniqueProduct,
        isOpenPopupCart
    }
})
